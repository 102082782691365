<template>
  <base-wrapper>
    <div class="space-y-4">
      <div class="flex items-center justify-end gap-x-2">
        <base-input
          v-model="filter.search"
          :shadow="false"
          placeholder="Cari Kode"
          debounce
          @native-input="loadOrders"
          type="search"
        />
        <base-select
          fit
          :shadow="false"
          :options="[
            { key: 'all', label: 'Status', value: null },
            { key: 'not_paid', label: 'Belum Lunas', value: false },
            { key: 'paid', label: 'Lunas', value: true },
          ]"
          v-model="filter.is_paid"
          @change="loadOrders"
        />
      </div>
      <div>
        <datatable
          :columns="columns"
          :data="orders.data"
          :scroll-x="false"
          :total="orders.meta.page.total"
          :perPage="orders.meta.page.perPage"
          :currentPage="orders.meta.page.currentPage"
          :meta="orders.meta"
          cursor
          @pagechanged="onPageChanged"
        >
          <template v-slot:tbody="{ classes }">
            <tr
              v-for="(order, index) in orders.data"
              :key="order.id"
              :class="[
                classes.tr,
                index % 2 === 0 ? 'bg-white' : 'bg-gray-50',
                'cursor-pointer bg-white hover:bg-green-100',
              ]"
              @click="
                $router.push({
                  name: 'pembayaran.bayar-pinjaman-barang-sc',
                  params: { id: order.attributes.order_id },
                })
              "
            >
              <td :class="[classes.td]">
                <p>
                  <span class="font-bold text-gray-900">{{
                    order.attributes.origin_code
                  }}</span>
                  / <span>{{ order.attributes.destination_code }}</span>
                </p>
                <span class="text-xs text-gray-400">{{
                  order.attributes.createdAt | formatDate
                }}</span>
              </td>
              <td :class="classes.td">
                {{
                  getSingleIncluded(orders, order.relationships.order.data.id)
                    .attributes.destination_office_code
                }}
              </td>
              <td :class="classes.td">
                {{
                  getSingleIncluded(orders, order.relationships.order.data.id)
                    .attributes.origin_warehouse_code
                }}
              </td>
              <td :class="[classes.td, 'text-right']">
                {{
                  Math.max(
                    getSingleIncluded(orders, order.relationships.order.data.id)
                      .attributes.grand_total_price -
                      getSingleIncluded(
                        orders,
                        order.relationships.order.data.id
                      ).attributes.payment_amount,
                    0
                  ) | toCurrency
                }}
              </td>
              <td :class="[classes.td, 'text-right']">
                {{
                  getSingleIncluded(orders, order.relationships.order.data.id)
                    .attributes.grand_total_price | toCurrency
                }}
              </td>
              <td :class="[classes.td, 'text-center']">
                <base-badge
                  :color="order.attributes.is_paid ? 'green' : 'yellow'"
                  >{{
                    order.attributes.is_paid ? 'Lunas' : 'Belum Lunas'
                  }}</base-badge
                >
              </td>
              <td :class="[classes.td, 'text-center']">
                <base-badge color="indigo">{{
                  order.attributes.order_status
                }}</base-badge>
              </td>
            </tr>
          </template>
        </datatable>
      </div>
    </div>
    <loading v-if="loading" />
  </base-wrapper>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import { mapActions } from 'vuex';

export default {
  mixins: [requestMixin],
  data() {
    return {
      filter: {
        is_paid: false,
        search: null,
      },
      orders: {
        data: [],
        meta: {
          page: {
            total: 0,
          },
        },
      },
      loading: false,
    };
  },
  computed: {
    columns() {
      return [
        { id: 'code', name: 'No Faktur' },
        { id: 'destination_office_code', name: 'Kode SC' },
        { id: 'origin_warehouse_code', name: 'Kode Gudang' },
        {
          id: 'payment_remainder',
          name: 'Sisa Pembayaran',
          theadClass: 'text-right',
        },
        {
          id: 'total_price',
          name: 'Total Penjualan',
          theadClass: 'text-right',
        },
        { id: 'status', name: 'Status', theadClass: 'text-center' },
        { id: 'order_status', name: 'Status Order', theadClass: 'text-center' },
      ];
    },
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    async loadOrders(params = {}) {
      this.loading = true;

      const [res, error] = await this.request('/api/v1/orders', {
        params: {
          'page[limit]': 5,
          'filter[destination_office_category_id]': 3,
          include: 'order',
          'filter[search]': this.filter.search,
          'filter[is_paid]': this.filter.is_paid,
          'filter[is_loan]': true,
          'fields[simple-orders]':
            'order_id,order,origin_code,destination_code,createdAt,order_status,is_paid',
          'fields[orders]':
            'grand_total_price,payment_amount,destination_office_code,origin_warehouse_code',
          ...params,
        },
      });

      if (!error) {
        this.orders = res;
      }

      this.loading = false;
    },
    onPageChanged(page) {
      this.loadOrders({
        ...page,
      });
    },
  },
  created() {
    this.loadOrders();
  },
};
</script>
