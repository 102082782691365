<template>
  <base-card title="Detail Penjualan">
    <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-6">
      <div class="sm:col-span-6">
        <base-input
          label="No Faktur"
          placeholder="AB.001-001"
          inset
          fullwidth
          disabled
          v-model="order.data.attributes.origin_code"
        />
      </div>
      <div class="sm:col-span-3">
        <base-input
          :label="`Kode ${destinationOfficeTypeLabel}`"
          placeholder="N-12"
          inset
          fullwidth
          disabled
          :value="
            orderDestinationOffice
              ? destinationOfficeCode
              : null
          "
        />
      </div>
      <div class="sm:col-span-3">
        <base-input
          label="Nama Pemilik"
          placeholder="Dion"
          inset
          fullwidth
          disabled
          :value="
            orderDestinationOffice
              ? orderDestinationOffice.attributes.name
              : null
          "
        />
      </div>
      <div class="sm:col-span-3">
        <base-input
          label="Kode Gudang"
          placeholder="GD.AB-001"
          inset
          fullwidth
          disabled
          :value="order.data.attributes.origin_warehouse_code"
        />
      </div>
      <div class="sm:col-span-3">
        <base-input
          label="Wilayah Harga"
          placeholder="0"
          inset
          fullwidth
          disabled
          :value="order.data.attributes.area_code"
        />
      </div>
      <div class="sm:col-span-3">
        <base-input
          label="Total Penjualan"
          placeholder="100.000"
          inset
          fullwidth
          disabled
          :value="order.data.attributes.grand_total_price | toCurrency"
        />
      </div>
      <div class="sm:col-span-3">
        <base-input
          label="Metode Pengiriman"
          placeholder="Diambil"
          inset
          fullwidth
          disabled
          :value="
            order.data.attributes.order_shipment === 'delivery'
              ? 'Dikirim'
              : 'Diambil'
          "
        />
      </div>
    </div>
  </base-card>
</template>

<script>
export default {
  props: {
    order: Object,
  },
  computed: {
    orderDestinationOffice() {
      return this.getSingleIncluded(
        this.order,
        this.order.data.relationships['destination-office'].data.id
      );
    },
    destinationOfficeTypeLabel() {
      return this.orderDestinationOffice ? this.orderDestinationOffice.attributes.office_type.split('_').map(t => t.charAt(0).toUpperCase() + t.slice(1)).join(' ') : ''
    },
    destinationOfficeCode() {
      if (this.orderDestinationOffice.attributes.office_type !== 'member') {
        return this.orderDestinationOffice.attributes.code 
      }

      return this.orderDestinationOffice.attributes.is_active
        ? this.orderDestinationOffice.attributes.code
        : this.orderDestinationOffice.attributes.temp_code
    }
  },
};
</script>
