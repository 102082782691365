<template>
  <component
    :is="cardless ? 'div' : 'base-card'"
    title="Daftar Faktur Pembayaran"
  >
    <datatable :scroll-x="false" :columns="tableColumns">
      <template v-slot:tbody="{ classes }">
        <template v-for="payment in payments.data">
          <tr :key="payment.id" :class="[classes.tr]">
            <td :class="[classes.td]">
              <span class="font-bold text-gray-900">
                {{ payment.attributes.code }}
              </span>
            </td>
            <td :class="[classes.td]">
              {{ payment.attributes.destination_office_code }} /
              {{ payment.attributes.origin_warehouse_code }}
            </td>
            <td :class="[classes.td]">
              {{ payment.attributes.createdAt | formatDate }}
            </td>
            <td :class="[classes.td, 'text-right']">
              {{ payment.attributes.total_amount | toCurrency }}
            </td>
            <td
              v-if="columns.includes('state')"
              :class="[classes.td, 'text-center']"
            >
              <svg
                v-if="payment.attributes.state === 'pending'"
                title="Metode pembayaran harus diverifikasi terlebih dahulu"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="mx-auto h-5 w-5 text-yellow-500"
              >
                <path
                  fill-rule="evenodd"
                  d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
                  clip-rule="evenodd"
                />
              </svg>
              <svg
                v-else-if="payment.attributes.is_verified"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="mx-auto h-5 text-green-500"
                title="Pembayaran telah diverifikasi"
              >
                <path
                  fill-rule="evenodd"
                  d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <base-button v-else size="sm" @click="onVerifyPayment(payment)"
                >Verifikasi</base-button
              >
            </td>
            <td :class="[classes.td, 'text-right']">
              <button class="text-right" @click="onCollapse(payment)">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="mx-auto h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                  v-if="collapsedId === payment.id"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M5 15l7-7 7 7"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                  v-else
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
            </td>
          </tr>
          <template v-if="collapsedId === payment.id">
            <tr
              v-for="paymentMethod in paymentMethods.data"
              :key="`${payment.id}-${paymentMethod.id}`"
              :class="[classes.tr, 'bg-gray-50']"
            >
              <td :class="[classes.td]"></td>
              <td :class="[classes.td]">
                <template
                  v-if="paymentMethod.attributes.balance_used === 'restock'"
                  >Saldo Restock</template
                >
                <template v-else>
                  {{ paymentMethod.attributes.payment_method_type }}
                  <template v-if="paymentMethod.attributes.office_bank_name">
                    -
                    {{ paymentMethod.attributes.office_bank_name }}
                  </template>
                </template>
              </td>
              <td :class="[classes.td]">
                {{ paymentMethod.attributes.updatedAt | formatDate }}
              </td>
              <td :class="[classes.td, 'text-right']">
                {{ paymentMethod.attributes.payment_amount | toCurrency }}
              </td>
              <td
                v-if="columns.includes('state')"
                :class="[classes.td, 'text-center']"
              >
                <svg
                  v-if="paymentMethod.attributes.is_verified"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="mx-auto h-5 w-5 text-green-500"
                  title="Metode pembayaran telah diverifikasi"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <base-button
                  v-else
                  size="sm"
                  @click="onVerifyPaymentMethod(paymentMethod)"
                  >Verifikasi</base-button
                >
              </td>
              <td></td>
            </tr>
          </template>
        </template>
      </template>
      <template v-if="summary" v-slot:tfoot="{ classes }">
        <tfoot>
          <tr :class="classes.tr">
            <td colspan="2"></td>
            <td :class="[classes.td]">Total Penjualan</td>
            <td :class="[classes.td, 'text-right']">
              {{ orderPrice | toCurrency }}
            </td>
          </tr>
          <tr v-if="withTotalPaymentAmount" :class="classes.tr">
            <td colspan="2"></td>
            <td :class="[classes.td, 'pt-0']">Total Pembayaran</td>
            <td :class="[classes.td, 'pt-0 text-right']">
              {{ paymentAmount | toCurrency }}
            </td>
          </tr>
          <tr v-if="withTotalPaymentRemainder" :class="classes.tr">
            <td colspan="2"></td>
            <td :class="[classes.td, 'pt-0']">Total Kekurangan</td>
            <td :class="[classes.td, 'pt-0 text-right']">
              {{ Math.max(orderPrice - paymentAmount, 0) | toCurrency }}
            </td>
          </tr>
        </tfoot>
      </template>
    </datatable>
    <teleport to="body">
      <loading v-if="loading" />
    </teleport>
  </component>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import Teleport from 'vue2-teleport';

export default {
  mixins: [requestMixin],
  components: { Teleport },
  props: {
    payments: Object,
    orderPrice: Number,
    cardless: Boolean,
    columns: {
      type: Array,
      default: () => ['state'],
    },
    withTotalPaymentAmount: Boolean,
    withTotalPaymentRemainder: Boolean,
    paymentAmount: Number,
    summary: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['reload', 'verified'],
  data() {
    return {
      collapsedId: null,
      loadingPaymentMethods: false,
      loadingVerifyPayment: false,
      loadingVerifyPaymentMethod: false,
      paymentMethods: {
        data: [],
      },
    };
  },
  computed: {
    tableColumns() {
      return [
        { id: 'code', name: 'No Pembayaran' },
        { id: 'warehouse_code', name: 'Kode Gudang' },
        { id: 'date', name: 'Tanggal' },
        { id: 'amount', name: 'Total', theadClass: 'text-right' },
        this.columns.includes('state')
          ? { id: 'state', name: '', theadClass: 'text-center' }
          : null,
        { id: 'action', name: '', theadClass: 'text-right' },
      ].filter((i) => i !== null);
    },
    loading() {
      return (
        this.loadingPaymentMethods ||
        this.loadingVerifyPayment ||
        this.loadingVerifyPaymentMethod
      );
    },
  },
  methods: {
    async loadPaymentMethods() {
      this.loadingPaymentMethods = true;

      const [res, error] = await this.request(
        `/api/v1/payments/${this.collapsedId}/payment-methods`,
        {
          params: {
            'fields[payment-methods]':
              'updatedAt,payment_method_type,office_bank_name,payment_amount,is_verified,balance_used',
          },
        }
      );

      if (!error) {
        this.paymentMethods = res;
      }

      this.loadingPaymentMethods = false;
    },
    onCollapse(payment) {
      if (this.collapsedId === payment.id) {
        this.collapsedId = null;
      } else {
        this.collapsedId = payment.id;

        this.paymentMethods = {
          data: [],
        };

        this.loadPaymentMethods();
      }
    },
    async onVerifyPayment(payment) {
      this.loadingVerifyPayment = true;

      const [, error] = await this.request(
        `/api/v1/payments/${payment.id}/-actions/verify`,
        {
          method: 'post',
        }
      );

      if (!error) {
        this.$emit('verified');
      }

      this.loadingVerifyPayment = false;
    },
    async onVerifyPaymentMethod(paymentMethod) {
      this.loadingVerifyPaymentMethod = true;

      const [, error] = await this.request(
        `/api/v1/payment-methods/${paymentMethod.id}/-actions/verify`,
        {
          method: 'post',
        }
      );

      if (!error) {
        await this.loadPaymentMethods();

        if (
          this.paymentMethods.data.filter(
            (paymentMethod) => !paymentMethod.attributes.is_verified
          ).length < 1
        ) {
          this.$emit('reload');
        }
      }

      this.loadingVerifyPaymentMethod = false;
    },
  },
};
</script>
