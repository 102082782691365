var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-card',{attrs:{"title":"Detail Penjualan"}},[_c('div',{staticClass:"grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-6"},[_c('div',{staticClass:"sm:col-span-6"},[_c('base-input',{attrs:{"label":"No Faktur","placeholder":"AB.001-001","inset":"","fullwidth":"","disabled":""},model:{value:(_vm.order.data.attributes.origin_code),callback:function ($$v) {_vm.$set(_vm.order.data.attributes, "origin_code", $$v)},expression:"order.data.attributes.origin_code"}})],1),_c('div',{staticClass:"sm:col-span-3"},[_c('base-input',{attrs:{"label":`Kode ${_vm.destinationOfficeTypeLabel}`,"placeholder":"N-12","inset":"","fullwidth":"","disabled":"","value":_vm.orderDestinationOffice
            ? _vm.destinationOfficeCode
            : null}})],1),_c('div',{staticClass:"sm:col-span-3"},[_c('base-input',{attrs:{"label":"Nama Pemilik","placeholder":"Dion","inset":"","fullwidth":"","disabled":"","value":_vm.orderDestinationOffice
            ? _vm.orderDestinationOffice.attributes.name
            : null}})],1),_c('div',{staticClass:"sm:col-span-3"},[_c('base-input',{attrs:{"label":"Kode Gudang","placeholder":"GD.AB-001","inset":"","fullwidth":"","disabled":"","value":_vm.order.data.attributes.origin_warehouse_code}})],1),_c('div',{staticClass:"sm:col-span-3"},[_c('base-input',{attrs:{"label":"Wilayah Harga","placeholder":"0","inset":"","fullwidth":"","disabled":"","value":_vm.order.data.attributes.area_code}})],1),_c('div',{staticClass:"sm:col-span-3"},[_c('base-input',{attrs:{"label":"Total Penjualan","placeholder":"100.000","inset":"","fullwidth":"","disabled":"","value":_vm._f("toCurrency")(_vm.order.data.attributes.grand_total_price)}})],1),_c('div',{staticClass:"sm:col-span-3"},[_c('base-input',{attrs:{"label":"Metode Pengiriman","placeholder":"Diambil","inset":"","fullwidth":"","disabled":"","value":_vm.order.data.attributes.order_shipment === 'delivery'
            ? 'Dikirim'
            : 'Diambil'}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }