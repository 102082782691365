<template>
  <div>
    <base-modal
      :showing="visible && !validateConfirmationVisible"
      size="max-w-5xl"
      @close="onClose"
      @opened="onOpened"
    >
      <base-card
        title="Detail Laporan Penjualan P3S"
        description="Detail Laporan Penjualan P3S"
        :with-style="false"
      >
        <template #action>
          <base-badge
            v-if="statusBadgeType === 'validation'"
            :color="
              stockLoanMovementReport.data.attributes.valid ? 'green' : 'yellow'
            "
            >{{
              stockLoanMovementReport.data.attributes.valid ? 'Valid' : 'Draft'
            }}</base-badge
          >
          <base-badge
            v-else
            :color="
              stockLoanMovementReport.data.attributes.verified
                ? 'green'
                : 'yellow'
            "
            >{{
              stockLoanMovementReport.data.attributes.verified
                ? 'Diverifikasi'
                : 'Belum Diverifikasi'
            }}</base-badge
          >
        </template>
        <div class="space-y-6 border-t border-gray-200 py-5">
          <dl class="flex flex-row gap-x-4">
            <div class="w-full">
              <dt class="text-xs text-gray-700">No Laporan</dt>
              <dd class="mt-1 text-sm font-bold text-gray-900">
                {{ stockLoanMovementReport.data.attributes.code }}
              </dd>
            </div>
            <div class="w-full">
              <dt class="text-xs text-gray-700">Tanggal</dt>
              <dd class="mt-1 text-sm font-bold text-gray-900">
                {{
                  stockLoanMovementReport.data.attributes.createdAt | formatDate
                }}
              </dd>
            </div>
          </dl>
          <dl class="flex flex-row gap-x-4">
            <div class="w-full">
              <dt class="text-xs text-gray-700">Nama</dt>
              <dd class="mt-1 text-sm font-bold text-gray-900">
                {{ stockLoanMovementReport.data.attributes.name }}
              </dd>
            </div>
            <div class="w-full">
              <dt class="text-xs text-gray-700">Deksripsi</dt>
              <dd class="mt-1 text-sm font-bold text-gray-900">
                {{ stockLoanMovementReport.data.attributes.description }}
              </dd>
            </div>
          </dl>
          <dl class="flex flex-row gap-x-4">
            <div class="w-full">
              <dt class="text-xs text-gray-700">Barang</dt>
              <dd class="mt-1">
                <datatable
                  :scroll-x="false"
                  :columns="[
                    { id: 'code', name: 'Kode Barang' },
                    {
                      id: 'total_price',
                      name: 'Total Harga',
                      theadClass: 'text-right',
                    },
                  ]"
                >
                  <template #tbody="{ classes }">
                    <tr
                      :class="classes.tr"
                      v-for="item in items"
                      :key="item.id"
                    >
                      <td :class="classes.td">
                        {{ item.attributes.code }}
                      </td>
                      <td :class="[classes.td, 'text-right']">
                        {{ item.attributes.total_price | toCurrency }}
                      </td>
                    </tr>
                  </template>
                  <template v-if="items.length" #tfoot="{ classes }">
                    <tr :class="classes.tr">
                      <td :class="[classes.td]">
                        <p class="font-bold text-gray-900">Total</p>
                      </td>
                      <td :class="[classes.td, 'text-right']">
                        {{
                          stockLoanMovementReport.data.attributes.total_price
                            | toCurrency
                        }}
                      </td>
                    </tr>
                  </template>
                </datatable>
              </dd>
            </div>
          </dl>
          <dl class="flex flex-row gap-x-4">
            <div class="w-full">
              <dt class="text-xs text-gray-700">Pembayaran</dt>
              <dd class="mt-1">
                <datatable
                  :scroll-x="false"
                  :columns="[
                    { id: 'type', name: 'Metode Pembayaran' },
                    { id: 'bank', name: 'Bank' },
                    { id: 'amount', name: 'Jumlah', theadClass: 'text-right' },
                    { id: 'status', name: 'Status', theadClass: 'text-center' },
                    ...(withVerify ? [{ id: 'action', name: 'Aksi', theadClass: 'text-center' }] : []),
                  ]"
                >
                  <template #tbody="{ classes }">
                    <tr
                      :class="classes.tr"
                      v-for="payment in payments"
                      :key="payment.id"
                    >
                      <td :class="classes.td">
                        {{ payment.attributes.payment_method_type_name }}
                      </td>
                      <td :class="classes.td">
                        {{ payment.attributes.bank_name ?? '-' }}
                      </td>
                      <td :class="[classes.td, 'text-right']">
                        {{ payment.attributes.amount | toCurrency }}
                      </td>
                      <td :class="[classes.td, 'text-center']">
                        <base-badge
                          :color="
                            payment.attributes.verified ? 'green' : 'yellow'
                          "
                          >{{
                            payment.attributes.verified
                              ? 'Diverifikasi'
                              : 'Belum Diverifikasi'
                          }}</base-badge
                        >
                      </td>
                      <td v-if="withVerify" :class="[classes.td, 'text-center']">
                        <base-button v-if="!payment.attributes.verified" size="sm" @click="onVerifyPayment(payment)">Verifikasi</base-button>
                      </td>
                    </tr>
                  </template>
                  <template v-if="payments.length" #tfoot="{ classes }">
                    <tr :class="classes.tr">
                      <td :class="[classes.td]" colspan="2">
                        <p class="font-bold text-gray-900">Total</p>
                      </td>
                      <td :class="[classes.td, 'text-right']">
                        {{
                          stockLoanMovementReport.data.attributes.total_payment
                            | toCurrency
                        }}
                      </td>
                    </tr>
                  </template>
                </datatable>
              </dd>
            </div>
          </dl>
        </div>
        <div class="flex justify-end gap-2">
          <base-button v-if="false" color="white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
              />
            </svg>
            Hapus
          </base-button>
          <base-button
            v-if="
              withValidation && !stockLoanMovementReport.data.attributes.valid
            "
            @click="validateConfirmationVisible = true"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
              />
            </svg>
            Validasi
          </base-button>
          <base-button
            v-if="
              withVerify && !stockLoanMovementReport.data.attributes.verified && allPaymentVerified
            "
            @click="onVerify"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
              />
            </svg>
            Verifikasi
          </base-button>
        </div>
      </base-card>
    </base-modal>

    <validate-confirmation
      :id="stockLoanMovementReport.data.id"
      :visible="validateConfirmationVisible"
      @close="onCloseValidateConfirmation"
      @confirmed="onValidateConfirmed"
    />

    <loading v-if="loading" />
  </div>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import BaseModal from '@/components/base/BaseModal.vue';
import ValidateConfirmation from './validate-confirmation.vue';
import { mapActions } from 'vuex';

export default {
  mixins: [requestMixin],
  components: {
    BaseModal,
    ValidateConfirmation,
  },
  props: {
    visible: Boolean,
    id: String,
    statusBadgeType: {
      type: String,
      default: 'validation',
    },
    withValidation: {
      type: Boolean,
      default: true,
    },
    withVerify: Boolean
  },
  emits: ['close', 'validated', 'verified'],
  data() {
    return {
      loadingReport: false,
      loadingVerify: false,
      reload: true,
      stockLoanMovementReport: {
        data: {
          attributes: {},
          relationships: {
            items: {
              data: [],
            },
            payments: {
              data: [],
            },
          },
        },
        included: [],
      },
      validateConfirmationVisible: false,
    };
  },
  computed: {
    items() {
      return this.getIncludedByType(
        this.stockLoanMovementReport,
        'stock-loan-movements'
      );
    },
    payments() {
      return this.getIncludedByType(
        this.stockLoanMovementReport,
        'stock-loan-movement-report-payments'
      );
    },
    loading() {
      return this.loadingReport || this.loadingVerify
    },
    allPaymentVerified() {
      return this.payments.filter(payment => !payment.attributes.verified).length < 1
    }
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    async loadReport() {
      this.loadingReport = true;

      const [res, err] = await this.request(
        `/api/v1/stock-loan-movement-reports/${this.id}`,
        {
          params: {
            include: 'items,payments',
            'fields[stock-loan-movement-reports]':
              'code,valid,verified,createdAt,name,description,items,total_price,total_payment,payments',
            'fields[stock-loan-movements]': 'code,total_price',
            'fields[stock-loan-movement-report-payments]':
              'payment_method_type_name,bank_name,amount,verified',
          },
        }
      );

      if (!err) {
        this.stockLoanMovementReport = res;
      }

      this.loadingReport = false;
    },
    onOpened() {
      if (this.reload) {
        this.loadReport()
      }
    },
    onClose() {
      this.reload = true;
      this.$emit('close');
    },
    onCloseValidateConfirmation() {
      this.reload = false;
      this.validateConfirmationVisible = false;
    },
    onValidateConfirmed() {
      this.reload = true;
      this.validateConfirmationVisible = false;

      this.$emit('validated');
    },
    async onVerifyPayment(payment) {
      this.loadingVerify = true

      const [, err] = await this.request(`/api/v1/stock-loan-movement-report-payments/-actions/verify`, {
        method: 'post',
        data: {
          payment_id: payment.id
        }
      })

      if (err) {
        this.createAlert({
          data: this.getRequestErrorMessage(err),
          status: 'error',
        });
      } else {
        this.loadReport()
      }

      this.loadingVerify = false
    },
    async onVerify() {
      this.loadingVerify = true

      const [, err] = await this.request(`/api/v1/stock-loan-movement-reports/${this.stockLoanMovementReport.data.id}/-actions/verify`, {
        method: 'patch',
      })

      if (err) {
        this.createAlert({
          data: this.getRequestErrorMessage(err),
          status: 'error',
        });
      } else {
        this.$emit('verified')
      }

      this.loadingVerify = false
    }
  },
};
</script>
