var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('base-modal',{attrs:{"showing":_vm.visible && !_vm.validateConfirmationVisible,"size":"max-w-5xl"},on:{"close":_vm.onClose,"opened":_vm.onOpened}},[_c('base-card',{attrs:{"title":"Detail Laporan Penjualan P3S","description":"Detail Laporan Penjualan P3S","with-style":false},scopedSlots:_vm._u([{key:"action",fn:function(){return [(_vm.statusBadgeType === 'validation')?_c('base-badge',{attrs:{"color":_vm.stockLoanMovementReport.data.attributes.valid ? 'green' : 'yellow'}},[_vm._v(_vm._s(_vm.stockLoanMovementReport.data.attributes.valid ? 'Valid' : 'Draft'))]):_c('base-badge',{attrs:{"color":_vm.stockLoanMovementReport.data.attributes.verified
              ? 'green'
              : 'yellow'}},[_vm._v(_vm._s(_vm.stockLoanMovementReport.data.attributes.verified ? 'Diverifikasi' : 'Belum Diverifikasi'))])]},proxy:true}])},[_c('div',{staticClass:"space-y-6 border-t border-gray-200 py-5"},[_c('dl',{staticClass:"flex flex-row gap-x-4"},[_c('div',{staticClass:"w-full"},[_c('dt',{staticClass:"text-xs text-gray-700"},[_vm._v("No Laporan")]),_c('dd',{staticClass:"mt-1 text-sm font-bold text-gray-900"},[_vm._v(" "+_vm._s(_vm.stockLoanMovementReport.data.attributes.code)+" ")])]),_c('div',{staticClass:"w-full"},[_c('dt',{staticClass:"text-xs text-gray-700"},[_vm._v("Tanggal")]),_c('dd',{staticClass:"mt-1 text-sm font-bold text-gray-900"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.stockLoanMovementReport.data.attributes.createdAt))+" ")])])]),_c('dl',{staticClass:"flex flex-row gap-x-4"},[_c('div',{staticClass:"w-full"},[_c('dt',{staticClass:"text-xs text-gray-700"},[_vm._v("Nama")]),_c('dd',{staticClass:"mt-1 text-sm font-bold text-gray-900"},[_vm._v(" "+_vm._s(_vm.stockLoanMovementReport.data.attributes.name)+" ")])]),_c('div',{staticClass:"w-full"},[_c('dt',{staticClass:"text-xs text-gray-700"},[_vm._v("Deksripsi")]),_c('dd',{staticClass:"mt-1 text-sm font-bold text-gray-900"},[_vm._v(" "+_vm._s(_vm.stockLoanMovementReport.data.attributes.description)+" ")])])]),_c('dl',{staticClass:"flex flex-row gap-x-4"},[_c('div',{staticClass:"w-full"},[_c('dt',{staticClass:"text-xs text-gray-700"},[_vm._v("Barang")]),_c('dd',{staticClass:"mt-1"},[_c('datatable',{attrs:{"scroll-x":false,"columns":[
                  { id: 'code', name: 'Kode Barang' },
                  {
                    id: 'total_price',
                    name: 'Total Harga',
                    theadClass: 'text-right',
                  },
                ]},scopedSlots:_vm._u([{key:"tbody",fn:function({ classes }){return _vm._l((_vm.items),function(item){return _c('tr',{key:item.id,class:classes.tr},[_c('td',{class:classes.td},[_vm._v(" "+_vm._s(item.attributes.code)+" ")]),_c('td',{class:[classes.td, 'text-right']},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(item.attributes.total_price))+" ")])])})}},(_vm.items.length)?{key:"tfoot",fn:function({ classes }){return [_c('tr',{class:classes.tr},[_c('td',{class:[classes.td]},[_c('p',{staticClass:"font-bold text-gray-900"},[_vm._v("Total")])]),_c('td',{class:[classes.td, 'text-right']},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.stockLoanMovementReport.data.attributes.total_price))+" ")])])]}}:null],null,true)})],1)])]),_c('dl',{staticClass:"flex flex-row gap-x-4"},[_c('div',{staticClass:"w-full"},[_c('dt',{staticClass:"text-xs text-gray-700"},[_vm._v("Pembayaran")]),_c('dd',{staticClass:"mt-1"},[_c('datatable',{attrs:{"scroll-x":false,"columns":[
                  { id: 'type', name: 'Metode Pembayaran' },
                  { id: 'bank', name: 'Bank' },
                  { id: 'amount', name: 'Jumlah', theadClass: 'text-right' },
                  { id: 'status', name: 'Status', theadClass: 'text-center' },
                  ...(_vm.withVerify ? [{ id: 'action', name: 'Aksi', theadClass: 'text-center' }] : []),
                ]},scopedSlots:_vm._u([{key:"tbody",fn:function({ classes }){return _vm._l((_vm.payments),function(payment){return _c('tr',{key:payment.id,class:classes.tr},[_c('td',{class:classes.td},[_vm._v(" "+_vm._s(payment.attributes.payment_method_type_name)+" ")]),_c('td',{class:classes.td},[_vm._v(" "+_vm._s(payment.attributes.bank_name ?? '-')+" ")]),_c('td',{class:[classes.td, 'text-right']},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(payment.attributes.amount))+" ")]),_c('td',{class:[classes.td, 'text-center']},[_c('base-badge',{attrs:{"color":payment.attributes.verified ? 'green' : 'yellow'}},[_vm._v(_vm._s(payment.attributes.verified ? 'Diverifikasi' : 'Belum Diverifikasi'))])],1),(_vm.withVerify)?_c('td',{class:[classes.td, 'text-center']},[(!payment.attributes.verified)?_c('base-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.onVerifyPayment(payment)}}},[_vm._v("Verifikasi")]):_vm._e()],1):_vm._e()])})}},(_vm.payments.length)?{key:"tfoot",fn:function({ classes }){return [_c('tr',{class:classes.tr},[_c('td',{class:[classes.td],attrs:{"colspan":"2"}},[_c('p',{staticClass:"font-bold text-gray-900"},[_vm._v("Total")])]),_c('td',{class:[classes.td, 'text-right']},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.stockLoanMovementReport.data.attributes.total_payment))+" ")])])]}}:null],null,true)})],1)])])]),_c('div',{staticClass:"flex justify-end gap-2"},[(false)?_c('base-button',{attrs:{"color":"white"}},[_c('svg',{staticClass:"h-5 w-5",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"}})]),_vm._v(" Hapus ")]):_vm._e(),(
            _vm.withValidation && !_vm.stockLoanMovementReport.data.attributes.valid
          )?_c('base-button',{on:{"click":function($event){_vm.validateConfirmationVisible = true}}},[_c('svg',{staticClass:"h-5 w-5",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"}})]),_vm._v(" Validasi ")]):_vm._e(),(
            _vm.withVerify && !_vm.stockLoanMovementReport.data.attributes.verified && _vm.allPaymentVerified
          )?_c('base-button',{on:{"click":_vm.onVerify}},[_c('svg',{staticClass:"h-5 w-5",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"}})]),_vm._v(" Verifikasi ")]):_vm._e()],1)])],1),_c('validate-confirmation',{attrs:{"id":_vm.stockLoanMovementReport.data.id,"visible":_vm.validateConfirmationVisible},on:{"close":_vm.onCloseValidateConfirmation,"confirmed":_vm.onValidateConfirmed}}),(_vm.loading)?_c('loading'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }