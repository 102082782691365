<template>
  <base-modal :showing="visible" :size="size" @close="$emit('close')">
    <div :class="['space-y-4', center ? 'text-center' : '']">
      <svg
        v-if="icon"
        xmlns="http://www.w3.org/2000/svg"
        :class="[
          'mx-auto h-20 w-20',
          type === 'success' ? 'text-green-500' : 'text-red-500',
        ]"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        stroke-width="2"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
        />
      </svg>
      <p>{{ message }}</p>
      <template v-if="input">
        <p class="font-bold">{{ code }}</p>
        <base-input
          :shadow="false"
          fullwidth
          :placeholder="code"
          v-model="codeInput"
        />
      </template>
      <div class="grid grid-cols-2 gap-2">
        <base-button
          :disabled="input && codeInput !== code"
          fullwidth
          :color="type"
          @click="$emit('confirmed')"
          >Yakin</base-button
        >
        <base-button fullwidth color="white" @click="$emit('close')"
          >Batal</base-button
        >
      </div>
    </div>
  </base-modal>
</template>

<script>
import BaseModal from './BaseModal.vue';

export default {
  components: {
    BaseModal,
  },
  props: {
    message: String,
    visible: Boolean,
    icon: {
      type: Boolean,
      default: true,
    },
    center: {
      type: Boolean,
      default: true,
    },
    input: Boolean,
    code: String,
    size: {
      type: String,
      default: 'max-w-sm',
    },
    type: {
      type: String,
      default: 'danger',
    },
  },
  emits: ['close', 'confirmed'],
  data() {
    return {
      codeInput: null,
    };
  },
};
</script>
