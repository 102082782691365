<template>
  <section>
    <div class="mx-4 flex flex-col space-y-4 py-4 md:mx-6 md:py-6">
      <OrderInformation />

      <!-- Start Metode Pembayaran -->
      <div
        v-if="
          !dataPembayaran.is_paid &&
          paymentMethodsForm.length &&
          isPaymentsValid
        "
        class="mb-5 rounded-lg bg-white p-4 sm:p-6 md:p-8"
      >
        <div class="flex justify-between pb-5">
          <h3 class="text-lg font-bold leading-6 text-gray-900">
            Tambah Invoice
          </h3>
          <button
            @click="addField"
            type="button"
            class="bg-white-600 rounded-md border border-gray-300 py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-white hover:shadow-lg"
          >
            Tambah Metode
          </button>
        </div>

        <!-- Informasi Saldo Pembayaran Yang Digunakan (jika belum ada pembayaran) -->
        <div
          class="flex w-full flex-row gap-x-4 gap-y-2 pb-4"
          v-if="!hasInvoices && dataPembayaran.saldo_order_awal > 0"
        >
          <div class="relative w-full">
            <div class="field-inset-default">
              <label
                for="metode_order"
                class="block text-xs font-bold text-gray-700"
              >
                Metode Pembayaran
              </label>
              <input
                type="text"
                name="metode_pembayaran"
                id="metode_pembayaran"
                class="input-inset-select"
                autocomplete="off"
                value="Saldo Pembayaran"
                disabled
              />
            </div>
          </div>
          <div class="relative w-full">
            <div class="field-inset-default">
              <label
                for="metode_order"
                class="block text-xs font-bold text-gray-700"
              >
                Nominal Pembayaran
              </label>
              <input
                type="text"
                name="metode_pembayaran"
                id="metode_pembayaran"
                class="input-inset-select"
                autocomplete="off"
                :value="usedOrderBalance | toCurrency"
                disabled
              />
            </div>
          </div>
        </div>
        <!-- End Informasi Saldo Pembayaran Yang Digunakan -->

        <PaymentMethodInlineForm
          :methods="paymentMethodTypes"
          :withAppendMethod="false"
          v-model="paymentMethodsForm"
        />

        <div class="grid grid-cols-6 justify-between gap-4">
          <div class="col-span-3">
            <div class="field-inset-default">
              <label
                for="catatan"
                class="block text-xs font-bold text-gray-700"
              >
                Deskripsi Pembayaran
              </label>
              <input
                v-model="dataPembayaran.description"
                type="text"
                name="deskripsi"
                id="deskripsi"
                class="input-inset-select"
                placeholder="Masukkan Deskripsi Pembayaran(opsional)"
              />
            </div>
          </div>
          <div class="col-span-3">
            <div class="field-inset-default bg-gray-100">
              <label
                for="nama_pemilik"
                class="block text-xs font-bold text-gray-700"
              >
                Total Pembayaran
              </label>
              <input
                :value="Math.abs(getTotalPembayaran) | toCurrency"
                readonly
                type="text"
                name="nama_pemilik"
                id="nama_pemilik"
                class="input-inset-select bg-gray-100"
                placeholder="Masukkan Nominal Pembayaran"
              />
            </div>
          </div>
        </div>

        <div class="my-4 flex justify-end">
          <div class="flex">
            <div class="flex h-5 cursor-pointer items-center">
              <input
                id="cetak-faktur"
                aria-describedby="cetak-faktur"
                name="cetak-faktur"
                type="checkbox"
                value="true"
                v-model="cetakPdf.cetak"
                class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
            </div>
            <div class="ml-3 text-sm">
              <label for="cetak-faktur" class="font-medium text-gray-700">
                Cetak Faktur
              </label>
              <p id="cetak-faktur" class="text-gray-500">
                Simpan dan cetak faktur secara langsung
              </p>
            </div>
          </div>
        </div>

        <div class="flex justify-end">
          <button
            @click="addPayment"
            :disabled="isPaymentMethodEmpty"
            id="simpan_pembayaran"
            class="ml-3 inline-flex rounded-md border border-gray-300 bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-600 hover:shadow-lg disabled:cursor-pointer disabled:bg-gray-300 disabled:hover:shadow-none"
          >
            Validasi
          </button>
        </div>
        <!-- End Metode Pembayaran -->
        <div class="grid grid-cols-6 justify-between gap-4 pt-4">
          <div class="col-span-3"></div>
          <div v-if="selisihPembayaran !== 0" class="col-span-3">
            <div class="field-inset-default bg-gray-100">
              <label
                for="nama_pemilik"
                class="block text-xs font-bold text-gray-700"
              >
                {{ selisihPembayaran > 0 ? 'Kekurangan' : 'Kelebihan' }}
              </label>
              <input
                :value="Math.abs(selisihPembayaran) | toCurrency"
                readonly
                type="text"
                name="nama_pemilik"
                id="nama_pemilik"
                class="input-inset-select bg-gray-100"
                placeholder="Masukkan Nominal Pembayaran"
              />
            </div>
          </div>
        </div>
      </div>

      <BaseCard title="Daftar Faktur Pembayaran">
        <Datatable id="daftar_pembayaran" :paginated="false" :footer="false" class="mt-4">
          <template v-slot:thead>
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium tracking-wider text-gray-500"
                >
                  No. Pembayaran
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium tracking-wider text-gray-500"
                >
                  Kode Gudang
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium tracking-wider text-gray-500"
                >
                  Tanggal
                </th>
                <template>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium tracking-wider text-gray-500"
                  >
                    Total
                  </th>
                </template>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium tracking-wider text-gray-500"
                  colspan="2"
                ></th>
              </tr>
            </thead>
          </template>
          <template v-slot:tbody>
            <tbody v-if="hasInvoices > 0">
              <template v-for="(invoice, index) in dataPembayaran.invoices">
                <payment-row
                  :key="`invoice-${index}`"
                  :order="dataPembayaran"
                  :payment="invoice"
                  :collapsed="paymentAccordion.active !== invoice.id"
                  :any-active-row="paymentAccordion.active !== null"
                  v-on:toggle="togglePaymentAccordion(invoice.id)"
                  @verify-payment="(payment) => handleVerifyPayment(invoice)"
                  withVerification
                />
                <template
                  v-if="
                    paymentAccordion.active === invoice.id &&
                    !getLoadingPaymentMethods
                  "
                >
                  <template v-if="selectedPaymentPaymentMethods.length > 0">
                    <payment-detail-row
                      v-for="(
                        paymentMethod, index
                      ) in selectedPaymentPaymentMethods"
                      :key="`payment-methods-${index}`"
                      :payment-method="paymentMethod"
                      :included="getPaymentPaymentMethods.included"
                      :invoice="getRelationships(getOrder, invoice.id)"
                      @verify-payment-method="
                        (paymentMethod) =>
                          handleVerifyPaymentMethod(paymentMethod, invoice)
                      "
                      with-verification
                      with-date
                      with-note
                    />
                  </template>
                  <template v-else>
                    <tr :key="index">
                      <td
                        class="whitespace-nowrap bg-gray-50 px-6 py-4 text-sm font-medium text-gray-900"
                        colspan="6"
                      >
                        Tidak ada metode pembayaran di invoice ini
                      </td>
                    </tr>
                  </template>
                </template>
              </template>
            </tbody>
            <tbody v-else>
              <tr class="bg-white">
                <td
                  class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                  colspan="3"
                >
                  Belum ada riwayat transaksi
                </td>
              </tr>
            </tbody>
          </template>
          <template v-if="hasInvoices > 0" #tfoot>
            <tfoot>
              <tr>
                <td
                  class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                >
                  Total Faktur
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                >
                  Rp.
                  {{ getOrder.data.attributes.grand_total_price | toCurrency }}
                </td>
              </tr>
            </tfoot>
          </template>
        </Datatable>
      </BaseCard>

      <BaseCard v-if="isAccAtasanShown">
        <div class="relative w-full">
          <div class="field-inset-default">
            <label
              for="alasan_acc"
              class="block text-xs font-bold text-gray-700"
            >
              Alasan ACC Atasan
            </label>
            <textarea
              type="text"
              name="alasan_acc"
              id="alasan_acc"
              class="input-inset-select"
              placeholder="Masukkan Nominal Pembayaran"
              autocomplete="off"
              rows="5"
              v-model="dataPembayaran.acc_payment_reason"
            ></textarea>
          </div>
        </div>
      </BaseCard>

      <div>
        <div class="flex justify-between">
          <div>
            <button
              v-if="!dataPembayaran.is_valid_for_packing"
              @click="confirmation = !confirmation"
              type="button"
              class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-white hover:shadow-lg"
            >
              Batalkan Faktur Penjualan
            </button>
          </div>
          <div>
            <template
              v-if="!dataPembayaran.is_valid_for_packing && hasInvoices > 0"
            >
              <base-button
                v-if="dataPembayaran.is_paid"
                @click="validasi"
                id="bayar"
              >
                Proses Gudang
              </base-button>
              <base-button
                v-else-if="isAccAtasanShown"
                @click="validasiAccAtasan"
                id="bayar"
              >
                ACC Atasan
              </base-button>
            </template>
            <base-button
              color="white"
              to="/kasir/pembayaran/faktur-penjualan-pusat-ke-stockist"
            >
              Kembali
            </base-button>
          </div>
        </div>
      </div>

      <!-- MODAL CONFIRMATION-->
      <transition name="fade">
        <div
          v-if="confirmation"
          class="fixed inset-0 z-10 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <modal
            @modal-action="successModal"
            link="/kasir/pembayaran/faktur-penjualan-pusat-ke-stockist"
            :actions="false"
          >
            <template v-slot:svg>
              <div
                class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100"
              >
                <!-- Heroicon name: outline/check -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 text-red-600"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
            </template>
            <template v-slot:heading>
              <h3
                class="text-lg font-semibold leading-6 text-gray-900"
                id="modal-title"
              >
                {{
                  hasInvoices > 0
                    ? 'Sudah ada faktur pembayaran. Anda yakin ingin membatalkan?'
                    : 'Apakah anda yakin ingin membatalkan Penjualan?'
                }}
              </h3>
            </template>
            <template v-slot:actions>
              <button
                @click="cancelOrderFromCashier"
                class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
              >
                Ya
              </button>
              <button
                @click="confirmation = !confirmation"
                class="bg-white-600 focus:outline-none inline-flex w-full justify-center rounded-md border border-gray-300 px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-white hover:shadow-lg focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:text-sm"
              >
                Kembali
              </button>
            </template>
          </modal>
        </div>
      </transition>

      <!-- CONFIRMATION PAYMENT -->
      <transition name="fade">
        <div
          v-if="confirmationPayment"
          class="fixed inset-0 z-10 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <modal
            @modal-action="successModal"
            link="/kasir/pembayaran/faktur-penjualan-pusat-ke-stockist"
            :actions="false"
          >
            <template v-slot:svg>
              <div
                class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100"
              >
                <!-- Heroicon name: outline/check -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 text-red-600"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
            </template>
            <template v-slot:heading>
              <h3
                class="text-lg font-semibold leading-6 text-gray-900"
                id="modal-title"
              >
                Apakah anda yakin ingin menghapus invoice
                {{ invoice.attributes.code }} ?
              </h3>
            </template>
            <template v-slot:actions>
              <button
                @click="cancelPayment"
                class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
              >
                Ya
              </button>
              <button
                @click="confirmationPayment = !confirmationPayment"
                class="bg-white-600 focus:outline-none inline-flex w-full justify-center rounded-md border border-gray-300 px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-white hover:shadow-lg focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:text-sm"
              >
                Kembali
              </button>
            </template>
          </modal>
        </div>
      </transition>

      <!-- MODAL SUCCESS-->
      <transition name="fade">
        <div
          v-if="success"
          class="fixed inset-0 z-10 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <base-modal
            :showing="true"
            @close="success = false"
            :showClose="success"
            size="max-w-sm"
          >
            <div
              class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100"
            >
              <!-- Heroicon name: outline/check -->
              <svg
                class="h-6 w-6 text-green-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
            <div class="mt-3 text-center sm:mt-5">
              <h3
                class="text-lg font-bold leading-6 text-gray-900"
                id="modal-title"
              >
                Data berhasil disimpan
              </h3>
            </div>
            <div class="mt-5 sm:mt-6">
              <button
                @click="success = false"
                class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
              >
                Selesai
              </button>
            </div>
          </base-modal>
        </div>
      </transition>

      <!-- MODAL ERROR -->
      <transition name="fade">
        <div
          v-if="failed"
          class="fixed inset-0 z-10 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <modal :actions="false">
            <template v-slot:svg>
              <div
                class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100"
              >
                <!-- Heroicon name: outline/check -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 text-red-600"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
            </template>
            <template v-slot:heading>
              <h3
                class="text-lg font-bold leading-6 text-gray-900"
                id="modal-title"
              >
                {{ getError }}
              </h3>
            </template>
            <template #actions>
              <button
                @click.prevent="failed = false"
                class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
              >
                Selesai
              </button>
            </template>
          </modal>
        </div>
      </transition>
    </div>
    <!-- print -->
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :preview-modal="true"
      :enable-download="cetakPdf.download"
      :pdf-quality="2"
      :manual-pagination="false"
      :paginate-elements-by-height="5000"
      :filename="getOrder.data.attributes?.origin_code"
      :html2canvas="{
        scale: 4,
        logging: false,
        dpi: 192,
        letterRendering: true,
      }"
      pdf-orientation="portrait"
      ref="html2Pdf"
      pdf-format="a7"
      pdf-content-width="100mm"
    >
      <pdf-content slot="pdf-content" :payment="getPayment" />
    </vue-html2pdf>
  </section>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import dayjs from 'dayjs';
import Modal from '@/components/Modal.vue';
import BaseModal from '@/components/base/BaseModal.vue';
// import BaseButton from '@/components/base/BaseButton.vue';
import Datatable from '@/components/base/Datatable';
import VueHtml2pdf from 'vue-html2pdf';
import PdfContent from '@/layouts/LayoutPrintInvoice.vue';
import { StorageService } from '@/services/storage.service';
import PaymentRow from '@/components/kasir/detail-penjualan/PaymentRow.vue';
import PaymentDetailRow from '@/components/kasir/detail-penjualan/PaymentDetailRow.vue';
import PaymentMethodInlineForm from '@/components/payment/PaymentMethodInlineForm';
import { paymentMethodFormMixin } from '@/mixins/payment-method/payment-method-form.mixin';
import { sum } from '@/utils/array.js';
import OrderInformation from '@/components/kasir/detail-penjualan/OrderInformation.vue';

export default {
  name: 'TambahTransaksiPembayaran',
  mixins: [paymentMethodFormMixin],
  components: {
    Datatable,
    Modal,
    // BaseButton,
    VueHtml2pdf,
    PdfContent,
    BaseModal,
    PaymentRow,
    PaymentDetailRow,
    PaymentMethodInlineForm,
    OrderInformation,
  },
  data() {
    return {
      paymentMethodsForm: [],
      is_detail: false,
      success: false,
      failed: false,
      openDetail: false,
      openListOrders: false,
      confirmation: false,
      confirmationPayment: false,
      cetakPdf: {
        cetak: false,
        download: false,
      },
      searchOrderDetail: '',
      dataPembayaran: {
        id: '',
        no_penjualan: null,
        kode_stockist: null,
        nama_pemilik: null,
        kode_gudang: null,
        wilayah_harga: null,
        saldo_awal: null,
        saldo_order_awal: null,
        total_penjualan: null,
        is_paid: null,
        is_valid_for_packing: null,
        acc_payment_reason: null,
        office_type_creator: null,
        invoices: [],
        description: '',
        has_cashback: true,
      },
      invoice: {},
      paymentMethods: [
        {
          name: 'Cash',
          value: 'cash',
        },
        {
          name: 'Fee',
          value: 'fee',
        },
        {
          name: 'Rabat',
          value: 'rabat',
        },
        {
          name: 'Uang Jalan',
          value: 'uang_jalan',
        },
        {
          name: 'Bank',
          value: 'bank',
        },
        {
          name: 'Saldo Stockist',
          value: 'stockist',
        },
      ],
      paymentAccordion: {
        active: null,
      },
    };
  },
  async created() {
    this.setBank();

    await this.fetchPaymentMethodTypesByOffice({
      officeId: this.me.office_id,
      params: { 'filter[is_reserved]': false },
    });

    const handleEscape = () => {
      this.success = false;
      this.failed = false;
    };
    this.setEscape(handleEscape);
  },
  computed: {
    ...mapGetters({
      isLoadingStockist: 'stockists/getLoading',
      getStockists: 'stockists/getStockists',
      getPayment: 'payments/getPayment',
      getBanksByOffice: 'banks/getBanksByOffice',
      getOrders: 'orders/getOrders',
      getLoading: 'orders/getLoading',
      getOrder: 'orders/getOrder',
      getError: 'orders/getError',
      getPaymentPaymentMethods: 'payments/getPaymentMethods',
      getLoadingPaymentMethods: 'payments/getLoadingPaymentMethods',
      me: 'auth/getUser',
    }),
    selectedPaymentPaymentMethods() {
      return this.getPaymentPaymentMethods.data.filter(
        (item) => item.attributes.is_used
      );
    },
    saveable() {
      return !this.dataPembayaran.is_valid_for_packing && this.hasInvoices > 0;
    },
    isAccAtasanShown() {
      const order = this.getOrder.data;
      const cashback = order.attributes.cashback;
      const payments = this.getIncludedByType(this.getOrder, 'payments');
      const unverifiedPayments = payments.filter(
        (payment) => !payment.attributes.is_verified
      );
      const verifiedPayments = payments.filter(
        (payment) => payment.attributes.is_verified
      );

      // tidak muncul jika sudah valid / sudah acc atasan
      if (
        order.attributes.is_valid_for_packing ||
        order.attributes.is_paid ||
        order.attributes.acc_payment_reason
      ) {
        return false;
      }

      // muncul jika pembayaran pertama dan hanya menggunakan saldo dan saldonya 0
      if (
        payments.length &&
        verifiedPayments.length === 0 &&
        payments[0].attributes.is_only_using_order_balance &&
        payments[0].attributes.total_amount - cashback < 1
      ) {
        return true;
      }

      // tidak muncul jika ada invoice yang belum terverifikasi
      if (unverifiedPayments.length) {
        return false;
      }

      return true;
    },
    paymentMethodTypes() {
      return [
        {
          id: 'cash',
          name: 'Cash',
          value: 'cash',
        },
        {
          id: 'bank',
          name: 'Bank',
          value: 'bank',
        },
        {
          id: 'edc',
          name: 'EDC',
          value: 'edc',
        },
      ];
    },
    isPaymentsValid() {
      // kalo ada yg blm di verifikasi, return false
      return this.getOrder.included?.every((it) => {
        if (it.type === 'payments') {
          return it.attributes.is_verified;
        } else {
          return true;
        }
      });
    },
    hasInvoices() {
      return this.dataPembayaran.invoices.length;
    },
    isPaymentGreaterOrEqualOrder() {
      const payments = this.getOrder.included.filter(
        (it) => it.type == 'payments'
      );

      const payment = payments.reduce((prev, curr) => {
        return prev + curr.attributes.total_amount;
      }, 0);

      return payment >= this.getOrder.data.attributes.final_price;
    },
    usedOrderBalance() {
      // jika sudah ada pembayaran
      if (this.hasInvoices || this.dataPembayaran.saldo_order_awal < 1) {
        return 0;
      }

      // // Jika saldo order < penjualan ? gunakan semua saldo : gunakan saldo - penjualan
      return Math.min(
        this.dataPembayaran.saldo_order_awal,
        this.dataPembayaran.total_penjualan
      );
    },
    getTotalPembayaran() {
      return this.sum(this.paymentMethodsForm, 'value');
    },
    selisihPembayaran() {
      const totalPaid = sum(
        this.getIncludedByType(this.getOrder, 'payments'),
        (payment) => payment.attributes.total_amount
      );
      const totalPay = sum(this.paymentMethodsForm, (method) => method.value);

      return (
        this.getOrder.data.attributes.grand_total_price - totalPaid - totalPay
      );
      // return (
      //   (this.dataPembayaran.total_penjualan || 0) -
      //   this.usedOrderBalance -
      //   this.sum(this.paymentMethodsForm, 'value') -
      //   this.dataPembayaran.invoices.reduce(
      //     (prev, cur) => prev + cur.attributes.total_amount,
      //     0
      //   )
      // );
    },
    isPaymentMethodEmpty() {
      return this.paymentMethodsForm.some(
        (metode) => !metode.type || !metode.value
        // metode.nominalPembayaran === 0
      );
    },
    totalPembayaran() {
      return this.dataPembayaran.invoices.reduce(
        (prev, cur) => prev + cur.attributes.total_amount,
        0
      );
    },
    isCreatedByCurrentRole() {
      return (
        this.dataPembayaran.office_type_creator ===
        StorageService.getUser().current_role.toLowerCase()
      );
    },
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchStockists: 'stockists/fetchStockists',
      fetchBanksByOffice: 'banks/fetchBanksByOffice',
      fetchProducts: 'products/fetchProducts',
      fetchOrder: 'orders/fetchOrder',
      updateOrder: 'orders/updateOrder',
      createOrder: 'orders/createOrder',
      cancelOrder: 'orders/cancelOrder',
      accAtasan: 'orders/accAtasan',
      createPayment: 'payments/createPayment',
      updatePayment: 'payments/updatePayment',
      updatePaymentMethod: 'payment_methods/updatePaymentMethod',
      fetchPaymentPaymentMethods: 'payments/fetchPaymentPaymentMethods',
      verifyPayment: 'payments/verifyPayment',
      verifyPaymentMethod: 'payment_methods/verifyPaymentMethod',
      createPaymentMethod: 'payment_methods/createPaymentMethod',
      fetchPaymentMethodTypesByOffice:
        'payment_method_types/fetchPaymentMethodTypesByOffice',
    }),
    setBank() {
      if (this.$route.params.id) {
        this.is_detail = true;
        this.dataPembayaran.id = this.$route.params.id;
      }

      this.fetchBanksByOffice({
        office_id: StorageService.getUser().office_id,
        fields: {
          'office-banks': 'office_bank_type,bank_name,account_number',
        },
      }).then((res) => {
        const officeBankType = new Set([
          ...res.data.data.map(
            (officeBank) => officeBank.attributes.office_bank_type
          ),
        ]);

        this.paymentMethods = this.paymentMethods.filter((method) => {
          return (
            [...officeBankType].includes(method.value) ||
            method.value == 'stockist'
          );
        });
        this.officeBanks = res.data.data;
        this.setOrder(this.dataPembayaran.id);
      });
    },
    setOrder(id) {
      return this.fetchOrder({
        id: id,
        include:
          'destination-office,payments,origin-office,origin-warehouse,payment-methods',
        fields: {
          orders:
            'cancel_warehouse_description,order_shipment,cashback,has_cashback,origin_code,origin_warehouse_code,area_code,final_price,payment_details,is_valid_for_packing,is_paid,destination-office,payments,acc_payment_reason,origin-office,origin-warehouse,office_type_creator,payment-methods,grand_total_price',
          offices: 'code,name,balance,order_balance,office_type',
          payments:
            'code,total_amount,createdAt,is_verified,order_price,state,is_only_using_order_balance',
          'payment-methods': 'payment_amount',
          warehouses: 'code',
        },
      }).then((response) => {
        const stockistOffice = this.getRelationships(
          response.data,
          response.data.data.relationships['destination-office'].data.id
        );

        this.dataPembayaran.no_penjualan =
          response.data.data.attributes.origin_code;
        this.dataPembayaran.kode_stockist = this.getRelationships(
          response.data,
          response.data.data.relationships['destination-office'].data.id
        ).attributes.code;
        this.dataPembayaran.nama_pemilik = this.getRelationships(
          response.data,
          response.data.data.relationships['destination-office'].data.id
        ).attributes.name;
        this.dataPembayaran.kode_gudang = this.getRelationships(
          response.data,
          response.data.data.relationships['origin-warehouse'].data.id
        ).attributes.code;
        this.dataPembayaran.wilayah_harga =
          response.data.data.attributes.area_code;
        this.dataPembayaran.saldo_awal = stockistOffice.attributes.balance;
        this.dataPembayaran.saldo_order_awal =
          stockistOffice.attributes.order_balance;
        this.dataPembayaran.total_penjualan =
          response.data.data.attributes.final_price;
        this.dataPembayaran.is_paid = response.data.data.attributes.is_paid;
        this.dataPembayaran.is_valid_for_packing =
          response.data.data.attributes.is_valid_for_packing;
        this.dataPembayaran.acc_payment_reason =
          response.data.data.attributes.acc_payment_reason;
        this.dataPembayaran.office_type_creator =
          response.data.data.attributes.office_type_creator;
        this.dataPembayaran.invoices = response.data.data.relationships[
          'payments'
        ].data.map((invoice) => {
          return this.getRelationships(response.data, invoice.id);
        });
        this.dataPembayaran.has_cashback =
          !!response.data.data.attributes.has_cashback;

        if (this.dataPembayaran.is_paid) return;

        if (this.hasInvoices > 0) {
          this.paymentMethodsForm = [
            {
              type: null,
              value: null,
              id: null,
              bank: null,
            },
          ];
          return;
        }
      });
    },
    getDataDetails(params) {
      let dataDetail = [];
      params.map((param) => {
        const officeBankByType = this.officeBanks.filter(
          (officeBank) =>
            officeBank.attributes.office_bank_type ===
            param.jenisPembayaran.value
        );
        let data = {
          office_bank_id: param.officeBank
            ? param.officeBank.id
            : officeBankByType[0]?.id ?? null,
          payment_amount: parseInt(param.nominalPembayaran),
          transfer_date: param.date,
          balance_used:
            param.jenisPembayaran.value === 'stockist'
              ? param.jenisPembayaran.value
              : undefined,
        };
        dataDetail.push(data);
      });
      return dataDetail;
    },
    handleVerifyPayment(invoice) {
      this.verifyPayment({
        paymentId: invoice.id,
      }).then(() => {
        this.setOrder(this.dataPembayaran.id);
      });
    },
    handleVerifyPaymentMethod(paymentMethod, invoice) {
      this.verifyPaymentMethod({ paymentMethodId: paymentMethod.id }).then(
        () => {
          this.setOrder(this.dataPembayaran.id);
          this.fetchPaymentPaymentMethods({
            id: invoice.id,
            include: 'office-bank',
            fields: {
              'payment-methods':
                'payment_amount,office-bank,balance_used,is_verified,is_used,payment_method_type,origin_office_type',
              'office-banks': 'bank_name',
            },
          });
        }
      );
    },
    addField() {
      this.paymentMethodsForm.push({
        type: null,
        value: null,
        id: null,
        bank: null,
      });
    },
    successModal() {
      this.success = !this.success;
      this.clearData();
    },
    failedModal() {
      this.failed = !this.failed;
    },
    detailModal() {
      this.openDetail = !this.openDetail;
      this.clearData();
    },
    openPaymentConfirmation(invoice) {
      this.confirmationPayment = !this.confirmationPayment;
      this.invoice = invoice;
    },
    addPayment() {
      let payload = {
        data: {
          type: 'payments',
          attributes: {
            description: this.dataPembayaran.description,
          },
          relationships: {
            order: {
              data: {
                type: 'orders',
                id: this.dataPembayaran.id,
              },
            },
          },
        },
        include: 'order',
      };
      const orderPayload = {
        data: {
          type: 'orders',
          id: this.dataPembayaran.id,
          attributes: {
            has_cashback: this.dataPembayaran.has_cashback,
          },
        },
      };
      this.updateOrder(orderPayload);
      this.createPayment(payload).then(async (paymentResponse) => {
        if (!paymentResponse) {
          this.failed = true;
        } else {
          // bikin post request ke payment methods
          await this.createPaymentMethods({
            paymentResponse: paymentResponse,
          });

          if (this.cetakPdf.cetak || this.cetakPdf.download) {
            this.$refs.html2Pdf.generatePdf();
            this.setBank();
          } else {
            this.setOrder(this.dataPembayaran.id);
            this.success = true;
          }
        }
      });
    },
    async createPaymentMethods({ paymentResponse }) {
      if (paymentResponse) {
        // Create Payment Methods
        const batchRequests = this.paymentMethodsForm.map((method) => {
          const payload = this.getPayload(method);

          return this.createPaymentMethod({
            paymentId: paymentResponse.data.data.id,
            officeBankId: payload.relationships['office-bank'].data.id,
            attributes: payload.attributes,
            paymentMethodTypeId:
              payload.relationships['payment-method-type'].data.id,
          });
        });

        return await Promise.all(batchRequests);
      }
    },
    clearData() {
      this.dataPembayaran = {
        id: '',
        no_penjualan: null,
        kode_stockist: null,
        nama_pemilik: null,
        kode_gudang: null,
        wilayah_harga: null,
        saldo_awal: null,
        total_penjualan: null,
        is_valid_for_packing: null,
        acc_payment_reason: null,
        office_type_creator: null,
        invoices: [],
      };
      this.invoice = {};

      this.paymentMethodsForm = [
        {
          id: null,
          type: null,
          value: null,
          bank: null,
        },
      ];
    },
    cancelPayment() {
      const payload = {
        data: {
          type: 'payments',
          id: this.invoice.id,
          attributes: {
            deletedAt: new Date(),
          },
        },
      };

      this.updatePayment(payload).then((response) => {
        if (response) {
          this.confirmationPayment = !this.confirmationPayment;
          this.setOrder(this.dataPembayaran.id);
        } else {
          this.failed = true;
        }
      });
    },
    cancelOrderFromCashier() {
      const payload = {
        data: {
          type: 'orders',
          id: this.dataPembayaran.id,
          attributes: {
            cancel_payment_description: 'Lorem Ipsum',
          },
        },
      };
      this.cancelOrder(payload).then((response) => {
        if (response) {
          this.$router.push(
            '/kasir/pembayaran/faktur-penjualan-pusat-ke-stockist'
          );
        } else {
          this.failed = true;
        }
      });
    },
    validasi() {
      const payload = {
        data: {
          type: 'orders',
          id: this.dataPembayaran.id,
          attributes: {
            is_valid_for_packing: true,
            canceled_by_user_id: null,
            canceled_from: null,
            acc_payment_reason: this.dataPembayaran.acc_payment_reason,
          },
        },
      };
      this.updateOrder(payload).then((response) => {
        if (response) {
          this.$router.push(
            '/kasir/pembayaran/faktur-penjualan-pusat-ke-stockist'
          );
        } else {
          this.failed = true;
        }
      });
    },
    validasiAccAtasan() {
      this.accAtasan({
        id: this.dataPembayaran.id,
        reason: this.dataPembayaran.acc_payment_reason,
      }).then((response) => {
        if (response) {
          this.$router.push(
            '/kasir/pembayaran/faktur-penjualan-pusat-ke-stockist'
          );
        } else {
          this.failed = true;
        }
      });
    },
    getRelationships(resource, id) {
      let data = resource.included.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
    sum(data, key) {
      return data.reduce((a, b) => a + parseFloat(b[key] || 0), 0);
    },
    togglePaymentAccordion(id) {
      this.paymentAccordion.active =
        this.paymentAccordion.active === id ? null : id;

      if (this.paymentAccordion.active === id) {
        this.fetchPaymentPaymentMethods({
          id,
          include: 'office-bank',
          fields: {
            'payment-methods':
              'payment_amount,office-bank,balance_used,is_verified,transfer_date,is_used,payment_method_type,origin_office_type',
            'office-banks': 'bank_name',
          },
        });
      }
    },
    toggleCashback() {},
  },
};
</script>
